import Ajax from './axios'

export async function userInfoFull() {
    return await Ajax.get("/user/info/full")
}
export async function userInfo() {
    return await Ajax.get("/user/info")
}
export async function user_dashboard() {
    return await Ajax.get("/user/dashboard")
}
export async function kycAccesstoken() {
    return await Ajax.post("/kyc/accesstoken")
}
export async function kycApplicantId(data) {
    return await Ajax.post("/kyc/applicantId",data)
}
export async function user_ido_participation(data) {
    return await Ajax.post("/user/ido/participation",data)
}
export async function user_ido_claim(data) {
    return await Ajax.post("/user/ido/claim",data)
}
export async function user_airdrop(data) {
    return await Ajax.post("/user/airdrop",data)
}
export async function user_info(data) {
    return await Ajax.post("/user/info",data)
}
export async function user_avatar(data) {
    return await Ajax.post("/user/avatar",data)
}
export async function loginLogout(data) {
    return await Ajax.post("/login/logout",data)
}
export async function userStaking(data) {
    return await Ajax.post("/user/staking",data)
}
export async function userTx(data) {
    return await Ajax.post("/user/tx",data)
}
export async function userOverview() {
    return await Ajax.get("/user/overview")
}
export async function IdoClaim(data) {
    return await Ajax.post("/ido/claim",data)
}
export async function IdoClaimBasic(data) {
    return await Ajax.post("/ido/claim/basic",data)
}
export async function userStakeReceive(data) {
    return await Ajax.post("/user/stake/receive",data)
}
export async function userIdoClaimSend(data) {
    return await Ajax.post("/user/ido/claim/send",data)
}
export async function userLog(data) {
    return await Ajax.post("/user/log",data)
}
export async function userLogTxList(data) {
    return await Ajax.post("/user/log/tx/list",data)
}
export async function userLogActList(data) {
    return await Ajax.post("/user/log/act/list",data)
}

