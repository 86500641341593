<template>
    <button class="Botton" :style="'width:'+width" v-bind="$attrs" @click="onChong">
        <div class="primary row-center-center cur active" 
            :class="[primaryType ? '' : 'error',fontfamily,padding?'clearPadding':'']" 
            v-if="type==='primary'"
            :style="'height:'+(height/100)+'rem;'"
        >
            <slot name="icon"></slot>
            <slot name="text"></slot>
            <slot name="loading"></slot>
        </div>

        <div class="default row-center-center cur active" 
            :class="[defaultType,fontfamily,padding?'clearPadding':'']"
            v-else-if="type === 'default'" 
            :style="'height:'+(height/100)+'rem;'"
        >   
            <template v-if="order">
                <slot name="icon"></slot>
                <slot name="text"></slot>
                <slot name="loading"></slot>
            </template>
            <template v-else>
                <slot name="text"></slot>
                <slot name="icon"></slot>
                <slot name="loading"></slot>
            </template>
            
        </div>

        <div class="disabled row-center-center cur active" 
            :class="[fontfamily,padding?'clearPadding':'']" 
            :style="'height:'+(height/100)+'rem;'"
            v-else-if="type==='disabled'">
            <slot name="icon"></slot>
            <slot name="text"></slot>
            <slot name="loading"></slot>
        </div>

        <div class="black row-center-center cur active"
            :class="[fontfamily,padding?'clearPadding':'']"
            v-else-if="type==='black'"
            :style="'height:'+(height/100)+'rem;'">
            <slot name="icon"></slot>
            <slot name="text"></slot>
            <slot name="loading"></slot>
        </div>
    </button>
</template>

<script>
    export default{
        props:{
            padding:{
                type: Boolean,
                default: false
            },
            type:{
                type: String,
                default: 'primary'
            },
            primaryType: {
                type: Boolean,
                default: true
            },
            defaultType:{
                type: String,
                default: '' // success , fail, whiteFrame, blackBorder
            },
            height:{
                type: Number,
                default: 48
            },
            fontSize:{
                type: Number,
                default: 14
            },
            fontfamily:{
                type: String,
                default: ''
            },
            width:{
                type: String,
            },
            order:{
                type:Boolean,
                default: true
            }
        },
        watch:{
            defaultType:{
                handler(newval){
                    if(newval){
                        if(newval !== 'success' && newval !== 'fail' && newval !== 'whiteFrame' && newval !== 'blackBorder'){
                            throw('Can only be "success" and "fail" and "whiteFrame"')
                        }
                    }
                },
                immediate:true
            }
        },
        data(){
            return{

            }
        },
        methods:{
            onChong(){
                this.$emit('onChong')
            }
        }
    }
</script>

<style scoped lang="less">
.Botton{
    width: fit-content;
    border: none;
    outline: none;
    background: initial;
    display: block;
    padding: 0;
    .disabled,.black{
        width: 100%;
        height: 100%;
        padding: 0 .16rem;
        border-radius: .24rem;
        background: #D4D6D8;
        color: #6F757B;
        img{
            width: .16rem;
            height: .16rem;
        }
        span{
            margin-left: .08rem;
        }
    }
    .black{
        background: #292B2E;
        color: #F4F5F5;
    }
    .default{
        width: 100%;
        height: 100%;
        border-radius: .24rem;
        border: 1px solid #a1a1a1;
        padding: 0 .12rem;
        color: #A1A6AA;
        img{
            width: .16rem;
            height: .16rem;
        }
        span{
            margin-left: .08rem;
        }
    }
    .success{
        border: 1px solid #039855;
        span{
            color: #039855;
        }
    }
    .fail{
        border: 1px solid #D7001D;
        span{
            color: #D7001D;
        }
    }
    .whiteFrame{
        border: 1px solid #ECEDEE;
        span{
            color: #ECEDEE;
        }
    }
    .blackBorder{
        border: 1px solid #292B2E;
        span{
            color: #292B2E;
        }
    }
    .primary{
        width: 100%;
        height: 100%;
        background: #039855;
        border-radius: .24rem;
        padding: 0 .16rem;
        color: #F4F5F5;
        img{
            width: .16rem;
            height: .16rem;
        }
        span{
            margin-left: .08rem;
        }
    }
    .clearPadding{
        padding-left: 0;
        padding-right: 0;
    }
    .error{
        background: #D7001D;
    }
}
</style>