import Vue from 'vue'
import App from './App.vue'
import './assets/css/reset.css'
import './assets/css/library.css'
import { Button ,Table, TableColumn,Input, Pagination,Select, Option} from 'element-ui';
import router from './router/index'
import tool from '@/assets/js/tool'
import store from '@/store/index'
Vue.prototype.$tool = tool
import '@/icons'
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Option)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
