import Ajax from './axios'

export async function idoFunded() {
    return await Ajax.get("/ido/funded")
}
export async function subscribe(data) {
    return await Ajax.post("/subscribe",data)
}
export async function ido_List(data) {
    return await Ajax.post("/ido/list",data)
}
export async function ido_register(data) {
    return await Ajax.post("/ido/register",data)
}
export async function ido_info(id) {
    return await Ajax.get("/ido/info/"+id)
}
export async function ido_infobasic(id) {
    return await Ajax.get("/ido/info/basic/"+id)
}
// 获取社交网站列表
export async function getSocialWebsiteLogo(site) {
	return await Ajax.get("/info/getSocialWebsiteLogo?site=" + site)
}
export async function login_logout() {
	return await Ajax.post("/login/logout")
}
export async function infoChain() {
	return await Ajax.get("/info/chain")
}

