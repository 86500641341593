import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        userInfo:{},
        MetaMaskProvider : 'ethereum',
        NaboxProvider : 'NaboxWallet',
        getAddress: null,
    },
    actions:{

    },
    mutations:{
        setUserInfo(state, val){
            state.userInfo = val.val
        },
        setAddress(state,val){
            state.getAddress = val
        },
    },
    plugins: [createPersistedState({
        key: 'userinfor',
        storage: window.localStorage,
    })]
})


export default store