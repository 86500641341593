import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../view/register/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../view/index.vue'),
    children: [
      { path: '/', name: 'home', component: () => import('@/view/home/index') },
      { path: '/home/more', name: 'more', component: () => import('@/view/home_More/index') },
      { path: '/user/history', name: 'history', component: () => import('@/view/history/index') },
      { path: '/airdrops', name: 'airdrops', component: () => import('@/view/airdrops/index') },
      { path: '/ido/details', name: 'launchpad', component: () => import('@/view/idoDetails/index.vue')},
      { path: '/buySuccess', name: 'buySuccess', component: () => import('@/view/buySuccess/index.vue')},
      { path: '/userInfo', name: 'userInfo', component: () => import('@/view/userInfo/index.vue')},
      { path: '/staking', name: 'staking', component: () => import('@/view/staking/index.vue')},
      { path: '/stats', name: 'stats', component: () => import('@/view/stats/index.vue')},
      { path: '/bridge', name: 'bridge', component: () => import('@/view/bridge/index.vue')}
    ]
  },
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return { x: 0, y: 0 }
      }
  },
  routes
})

export default router
