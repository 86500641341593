import axios from "axios";
import qs from "qs"; 
import { Message } from 'element-ui';
import router from '../router/index'
import store from '@/store/index'


axios.interceptors.request.use(
    config => {
        if (localStorage.token) {
            config.headers.token = localStorage.token
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

let flage = true
let Times = ''
axios.interceptors.response.use(
    response => {　
        if(response.data.code === 501){
            localStorage.removeItem('token')
            if(flage){
                flage = false
                clearTimeout(Times)
                Times = setTimeout(() => {
                    flage = true
                },1200)
                Message.closeAll()
                Message({
                    type: 'error',
                    showClose: true,
                    message: 'Login invalid!'
                })
                store.commit({
                    type: 'setUserInfo',
                    val: {}
                })
                store.commit('setAddress','')
                router.push('/register')
            }
        }else if(response.data.code != 0 && response.data.code != 534){
            Message.closeAll()
            Message({
                type: 'error',
                showClose: true,
                message: response.data.msg
            })
        }
        return response;
    },
    error => {
        return Promise.resolve(error.response);
    }
);


axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.timeout = 20000; 

function checkStatus(response) {
    return new Promise((resolve, reject) => {
        if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
            try {
                resolve(response.data);
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                // Message({
                //     type: 'error',
                //     showClose: true,
                //     message: response.msg
                // })
            } catch (e) {
                console.log(e)
            }
        }
    });
}

export default {
    post(url, params) {
        return axios({
            method: "post",
            url,
            data: params
        }).then(response => {
            return checkStatus(response);
        });
    },
    get(url, params) {
        params = qs.stringify(params);
        return axios({
            method: "get",
            url,
            params
        }).then(response => {
            return checkStatus(response);
        });
    }
};