<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import { userLog } from '@/api/userinfo'
export default {
  name: 'App',
  data(){
    return {
      activity: '',
    }
  },
  mounted() {
    (function (doc, win) {
      var docE1 = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
          var clientWidth = docE1.clientWidth;
          if (!clientWidth) return;
          
            docE1.style.fontSize = 100 * (clientWidth / 1440) + 'px';
        };
      if (!doc.addEventListener) return;
      win.addEventListener(resizeEvt, recalc, false);
      doc.addEventListener('DOMContentLoaded', recalc, false);
    })(document, window);

    try {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts[0]) {
          this.activity = "WALLET_CONNECTED"
        }else{
          this.activity = "WALLET_DISCONNECTED"
        }
        this.userLog('Metamask')
      });
      window.NaboxWallet.on("accountsChanged", (accounts) => {
        if (accounts[0]) {
          this.activity = "WALLET_CONNECTED"
        }else{
          this.activity = "WALLET_DISCONNECTED"
        }
        this.userLog('Nabox')
      });
    } catch (error) {
      console.log(error)
    }
  },
  methods:{
    async userLog(val){
      await userLog({
        "activity": this.activity,
        "module": "User",
        info: val
      })
    }
  }
}
</script>

<style>
/* 隐藏谷歌人机校验提示 */
.grecaptcha-badge {
  display: none;
}

.el-message .el-message__icon {
  font-size: .24rem;
}

.customize .el-message__content {
  font-size: .14rem;
}
</style>
