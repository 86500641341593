import Ajax from './axios'

export async function mailRegister(data) {
    return await Ajax.post("/mail/register",data)
}
export async function mailSendCaptcha(data) {
    return await Ajax.post("/mail/sendCaptcha",data)
}
export async function mailLogin(data) {
    return await Ajax.post("/mail/login",data)
}
export async function sendChangePwdMailCaptcha(data) {
    return await Ajax.post("/mail/sendChangePwdMailCaptcha",data)
}

export async function mailChangePwd(data) {
    return await Ajax.post("/mail/changePwd",data)
}
export async function mailCaptchaValid(data) {
    return await Ajax.post("/mail/captcha/valid",data)
}
export async function buildLoginNonce(address) {
    return await Ajax.post("/login/buildLoginNonce/"+address)
}
export async function loginRebind(data) {
    return await Ajax.post("/login/rebind",data)
}


