import { ethers } from "ethers";
import router from '@/router/index'
import store from "@/store/index";
import { buildLoginNonce, loginRebind } from '@/api/register'

async function LoginRebind(data,_this){
    const res = await loginRebind(data)
    if(res.code === 0){
        let userInfo = store.state.userInfo
        let userInfoAddress = store.state.userInfo.address
        _this.$tool.message('The wallet address is bound successfully.','success')
        _this.$router.push('/')
        if(!userInfoAddress){
            userInfo.address = data.address
            store.commit({
                type: 'setUserInfo',
                val: userInfo
            })
        }
        store.commit('setAddress', data.address)
    }else{
        store.commit('setAddress', '')
        _this.$tool.message('Wallet address binding failed.','error')
    }
}

export async function metamaskWallet(ethereum = 'ethereum', _this) {
    if (typeof window[ethereum] === "undefined") {
        if(ethereum === 'ethereum'){
            alert("Please install MetaMask")
        }else{
            alert("Please install Nabox Wallet")
        }
    } else {
        const provider = new ethers.providers.Web3Provider(window[ethereum]);
        let address = []
        try {
            const ifAddress = window[ethereum].selectedAddress
            if(ifAddress){
                address[0] = ifAddress
            }else{
                address = await window[ethereum].request({ method: 'eth_requestAccounts' });
            }
        } catch (error) {
            console.log(error)
            // _this.$tool.message(error, 'error')
        }
        if (address[0]) {
            sessionStorage.setItem('ethereumType',ethereum)
            store.commit('setAddress', address[0])
            // 是否绑定过钱包
            let USERINFO = localStorage.getItem('userinfor')
            USERINFO = JSON.parse(USERINFO)
            if(Object.keys(USERINFO.userInfo).length > 0){
                if(USERINFO.userInfo.bindAddress || USERINFO.userInfo.address){
                    // 判断绑定地址和连接地址是否一致
                    let boxAddress = address[0]
                    let userInfoAddress = store.state.userInfo.address
                    boxAddress = boxAddress.toLowerCase()
                    userInfoAddress = userInfoAddress.toLowerCase()
                    if(boxAddress != userInfoAddress){
                        _this.$tool.message('The connected wallet address is inconsistent with the bound wallet address.','error')
                    }
                    if(Object.keys(_this.$route.query).length > 0){
                        router.back()
                    }else{
                        router.push('/')
                    }
                    return false
                }
            }
            // 签名
            const code = await buildLoginNonce(address[0]);
            const jsonRpcSigner = provider.getSigner();
            const signature = await jsonRpcSigner.signMessage(code.data);
            await LoginRebind({ address: address[0], nonce: code.data, sig: signature, wallet: ethereum === 'ethereum' ? 'MetaMask' : 'Nabox' },_this);
            
            if(Object.keys(_this.$route.query).length > 0){
                router.back()
            }else{
                router.push('/')
            }
        }
    }
}