import { Message } from 'element-ui';
import router from '@/router/index'
import { login_logout } from '@/api/require'

import store from '@/store/index'
function message(message = 'custom content', type = 'info', duration = 3000) {
    Message.closeAll()
    Message({
        type,
        duration,
        showClose: true,
        message
    })
}

async function Copy(val) {
    let target = document.createElement('input') //创建input节点
    target.value = val // 给input的value赋值
    target.style.position = 'absolute'
    target.style.top = '-99999px'
    document.body.appendChild(target) // 向页面插入input节点
    target.select() // 选中input
    try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        alert('copy successfully')
    } catch {
        alert('Your browser does not support copying')
    }
}

// 退出
async function signOut(_this) {
    await login_logout()
    store.commit({
        type: 'setUserInfo',
        val: {}
    })
    store.commit('setAddress','')
    window.localStorage.removeItem('token')

    router.push('/register')
}

function countDownFun(time,flag) {
    time--;
    let nowTime = new Date().getTime(); // 获取当前时间
    if (time >= nowTime) {
        var secondTime = 0 //秒
        var minuteTime = 0; // 分
        var hourTime = 0; // 小时
        var today = 0 //天
        // 全部剩余多少秒
        var seconds = Math.ceil((time - nowTime) / 1000)

        hourTime = Math.floor(seconds / 3600)
        //天数
        today = Math.floor(hourTime / 24)
        //小时
        hourTime = Math.floor(hourTime % 24) < 10 ? '0' + Math.floor(hourTime % 24) : Math.floor(hourTime % 24)
        // 分
        minuteTime = Math.floor(seconds / 60 % 60) < 10 ? '0' + Math.floor(seconds / 60 % 60) : Math.floor(seconds / 60 % 60)
        //秒
        secondTime = Math.floor(seconds % 60) < 10 ? '0' + Math.floor(seconds % 60) : Math.floor(seconds % 60)

        if(flag){
            return today + 'd ' + hourTime + 'h ' + minuteTime + 'm ' + secondTime + 's'
        }else{
            return today + 'd ' + hourTime + 'h ' + minuteTime + 'm'
        }
        
    } else {
        if(flag){
            return "00d 00h 00m 00s";
        }else{
            return "00d 00h 00m";
        }
        
    }
}


function initCountdown(endTime, callback, flag=false) {
    if(endTime){
        let time
        if(flag){
            time = '00d 00h 00m 00s'
        }else{
            time = '00d 00h 00m'
        }
        let Interval = ''
        if (endTime - Date.now() > 0) {
            time = countDownFun(endTime,flag); //首次
            //定时器
            Interval = setInterval(function () {
                if (endTime - Date.now() <= 0) {
                    clearInterval(Interval);
                    if(flag){
                        time = '00d 00h 00m 00s'
                    }else{
                        time = '00d 00h 00m'
                    }
                } else {
                    time = countDownFun(endTime,flag);
                }
                callback(time)
            }, 1000);
        } else {
            callback(time)
        }
    }else{
        if(flag){
            callback('00d 00h 00m 00s')
        }else{
            callback('00d 00h 00m')
        }
        
    }
}


function toThousands(num) {
    if (!num) {
        return '0';
    }
    const N = num.toString().split('.');
    const int = N[0];
    const float = N[1] ? '.' + N[1] : '';
    return int.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + float;
}

// 加
function accAdd(arg1, arg2) {
    var r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
}

// 减
function accSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 乘
function accMul(arg1, arg2) {
    var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) { }
    try {
        m += s2.split(".")[1].length;
    } catch (e) { }

    return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
}

// 除
function accDiv(arg1, arg2) {
    var t1 = 0,
        t2 = 0,
        r1,
        r2;
    try {
        t1 = arg1.toString().split(".")[1].length;
    } catch (e) { }
    try {
        t2 = arg2.toString().split(".")[1].length;
    } catch (e) { }
    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));
    if (r2 == 0) {
        return Infinity;
    } else {
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
}


export default {
    message,
    accDiv,
    accMul,
    accSub,
    accAdd,
    toThousands,
    signOut,
    initCountdown
}