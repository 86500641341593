<template>
    <svg aria-hidden="true" class="svg-icon" v-bind="$attrs" @click="svgChong">
        <use :xlink:href="icon" />
    </svg>
</template>

<script>
    export default {
        name: 'svg-icon',
        props: {
            name: {
                type: String,
                //required: true
            }
        },
        computed: {
            icon () {
                return `#icon-${this.name}`
            }
        },
        methods:{
            svgChong(){
                this.$emit('svgChong')
            }
        }
    }
</script>

<style scoped>
.svg-icon {
    vertical-align: -0.15em;
    fill: currentColor;
    /* 通过给svg 图形元素设置fill= currentColor 图形元素的颜色会自动继承父级color的颜色 */
    overflow: hidden;
}
</style>
