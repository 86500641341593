<template>
    <div class="row-center-center">
        <div class="register" v-if="loginDisplayModule === 'register'">
            <div class="row-center-between Heading-H4-Bold log-in-title">
                <p>Register</p>
                <svg-icon class="cur active" name="register-Group2" style="width:.48rem;height:.48rem;"
                    @svgChong="setModule('login')" />
            </div>
            <p class="Paragraph-P2-Regular register-options">Nickname</p>
            <input type="text" :class="['Paragraph-P1-Regular input-Nickname', nickname ? 'selected' : '']"
                placeholder="enter your nickname" v-model="nickname">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !nickname">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">E-mail</p>
            <div class="row-center">
                <input type="text" :class="['Paragraph-P1-Regular input-Nickname', eMail ? 'selected' : '']"
                    placeholder="enter your e-mail" v-model="eMail">
                <Button :primaryType="false" fontfamily="Paragraph-P1-Regular" :type="!CodeFlag ? 'disabled' : 'primary'"
                    @onChong="getEMailCode(true)" style="width: 1.5rem;margin-top: 0.08rem;margin-left: 0.12rem;">
                    <template v-slot:text>
                        <span style="margin:0">{{ CodeText }}</span>
                    </template>
                </Button>
            </div>
            <p class="Paragraph-P2-Regular error-message" v-if="verifyCode">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">verification code</p>
            <input type="text" :class="['Paragraph-P1-Regular input-Nickname', eMailCode ? 'selected' : '']"
                placeholder="Enter email verification code" v-model="eMailCode">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !eMailCode">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">Password</p>
            <input type="password" :class="['Paragraph-P1-Regular input-Nickname', password ? 'selected' : '']"
                placeholder="enter your password" v-model="password" @blur="passwordFocus('1')">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !password">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">Repeat Password</p>
            <input type="password" :class="['Paragraph-P1-Regular input-Nickname', repeatPassword ? 'selected' : '']"
                placeholder="enter your password" v-model="repeatPassword">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !repeatPassword">Error Message ...</p>
            <!-- <div class="recaptcha row-center-between">
                <div class="row-center-between">
                    <div class="recaptcha-tran cur active" @click="getRecaptcha"></div>
                    <p class="recaptcha-jinter">I am human</p>
                </div>
                <svg-icon class="recaptcha-img" name="register-recaptcha"/>
                <img class="recaptcha-img" src="./image/recaptcha.png" alt="">
            </div> -->

            <div class="row-center-between register-foter">
                <div class="row-center">
                    <svg-icon class="google cur active" name="register-google" />
                    <svg-icon class="twitter cur active" name="register-twitter" />
                </div>
                <Button type="black" @onChong="RegisterPOST" fontfamily="Paragraph-P1-Regular" style="width: 1.92rem;">
                    <template v-slot:text>
                        <span>Register</span>
                    </template>
                </Button>
            </div>

            <div class="Paragraph-P1-Regular to-switch row-center">Already Registered? <span
                    class="Paragraph-P1-Semibold cur active weight" @click="setModule('login')">Log
                    In</span> </div>
        </div>

        <!--Log In  -->
        <div class="log-in" v-if="loginDisplayModule === 'login'">
            <div class="row-center-between Heading-H4-Bold log-in-title">
                <p>Log In</p>
                <svg-icon class="cur active" name="register-Group2" style="width:.48rem;height:.48rem;"
                    @svgChong="goBack" />
            </div>
            <p class="Paragraph-P2-Regular register-options">E-mail</p>
            <input type="text" :class="['Paragraph-P1-Regular input-Nickname', eMail ? 'selected' : '']"
                placeholder="enter your e-mail" v-model="eMail">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !eMail">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">Password</p>
            <input type="password" :class="['Paragraph-P1-Regular input-Nickname', password ? 'selected' : '']"
                placeholder="enter your password" v-model="password">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !eMail">Error Message ...</p>
            <div class="row-center-between register-foter">
                <div class="row-center">
                    <svg-icon class="google cur active" name="register-google" />
                    <svg-icon class="twitter cur active" name="register-twitter" />
                </div>

                <Button type="black" @onChong="LogInConfing" fontfamily="Paragraph-P1-Regular" style="width: 1.92rem;">
                    <template v-slot:text>
                        <span>Log In</span>
                    </template>
                    <template v-slot:loading v-if="loading">
                        <i class="el-icon-loading" style="margin-left:0.08rem;"></i>
                    </template>
                </Button>
            </div>

            <div class="row-center-between">
                <div class="Paragraph-P1-Regular to-switch row-center">New here? <span
                        class="Paragraph-P1-Semibold cur active" style="font-weight: 600;margin-left:3px;"
                        @click="setModule('register')">Register</span> </div>
                <div class="Paragraph-P1-Regular to-switch"><span class="Paragraph-P1-Semibold cur active"
                        style="font-weight: 600" @click="loginDisplayModule = 'forgetThePassword'">Forgot Password? </span>
                </div>
            </div>
        </div>

        <!-- forget the password -->
        <div class="forget-the-password" v-if="loginDisplayModule === 'forgetThePassword'">
            <div class="row-center-between Heading-H4-Bold log-in-title">
                <p>Forgot Password</p>
                <svg-icon class="cur active" name="register-Group2" style="width:.48rem;height:.48rem;"
                    @svgChong="ForgotPassword" />
            </div>

            <p class="Paragraph-P2-Regular register-options">E-mail</p>
            <div class="row-center">
                <input type="text" :class="['Paragraph-P1-Regular input-Nickname', eMail ? 'selected' : '']"
                    placeholder="enter your e-mail" v-model="eMail">
                <Button :primaryType="false" fontfamily="Paragraph-P1-Regular" :type="!CodeFlag ? 'disabled' : 'primary'"
                    @onChong="getEMailCode(false)" style="width: 1.5rem;margin-top: 0.08rem;margin-left: 0.12rem;">
                    <template v-slot:text>
                        <span style="margin:0">{{ Send }}</span>
                    </template>
                </Button>
            </div>
            <p class="Paragraph-P2-Regular error-message" v-if="verifyCode">Error Message ...</p>

            <p class="Paragraph-P2-Regular register-options">E-mail verification code</p>
            <input type="text" :class="['Paragraph-P1-Regular input-Nickname', eMailCode ? 'selected' : '']"
                placeholder="Enter email verification code" v-model="eMailCode">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !eMailCode">Error Message ...</p>
            <Button type="black" fontfamily="Paragraph-P1-Regular" style="width:100%;margin-top: .24rem;"
                @onChong="ResetPassword()">
                <template v-slot:text>
                    <span>Reset Password</span>
                </template>
            </Button>

            <div class="row-center-between">
                <div class="Paragraph-P1-Regular to-switch row-center">Back to <span
                        class="Paragraph-P1-Semibold cur active weight" @click="loginDisplayModule = 'login'">Log In</span>
                </div>
                <div class="Paragraph-P1-Regular to-switch row-center">New here? <span
                        class="Paragraph-P1-Semibold cur active weight" @click="loginDisplayModule = 'register'">Register
                    </span> </div>
            </div>
        </div>

        <!-- 设置新密码 -->
        <div class="set-new-password" v-if="loginDisplayModule === 'newPassword'">
            <p class="Paragraph-P2-Regular register-options">Password</p>
            <input type="password" @blur="passwordFocus('2')" @input="onChongInput"
                :class="['Paragraph-P1-Regular input-Nickname', password ? 'selected' : '']"
                placeholder="enter your password" v-model="password">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !password">Error Message ...</p>
            <p class="Paragraph-P2-Regular register-options">Repeat Password</p>
            <input type="password" @blur="passwordFocus('3')" @input="onChongInput"
                :class="['Paragraph-P1-Regular input-Nickname', repeatPassword ? 'selected' : '', check && !repeatPassword ? 'checkNO' : '']"
                placeholder="enter your password" v-model="repeatPassword">
            <p class="Paragraph-P2-Regular error-message" v-if="check && !repeatPassword">Error Message ...</p>
            <p class="error-toset" v-if="Okerror">The two passwords entered are inconsistent</p>

            <Button type="black" fontfamily="Paragraph-P1-Regular" style="width:100%;margin-top: .24rem;"
                @onChong="UpdatePassword()">
                <template v-slot:text>
                    <span>Update Password</span>
                </template>
            </Button>
        </div>

        <!-- 2FA验证 -->
        <div class="E-mail-verification" v-if="loginDisplayModule === 'verify'">
            <div class="row-center-between Heading-H4-Bold log-in-title">
                <p>Two-Factor Authentication</p>
                <svg-icon class="cur active" name="register-Group2" style="width:.48rem;height:.48rem;" />
            </div>
            <p class="Paragraph-P2-Regular register-options">Two-Factor Authentication Code</p>
            <input :class="['Paragraph-P1-Regular input-Nickname', password ? 'selected' : '']"
                placeholder="enter your 2FA code" v-model="password">
            <p class="error-toset">Error Message ...</p>

            <div class="row-center-between register-foter">
                <div class="Paragraph-P1-Regular to-switch cur active" style="margin: 0">
                    <span class="Paragraph-P1-Semibold cur active" @click="loginDisplayModule = 'login'">Send</span>
                    Another Code
                </div>

                <Button type="black" fontfamily="Paragraph-P1-Regular" style="width: 1.92rem;">
                    <template v-slot:text>
                        <span>Log In</span>
                    </template>
                </Button>
            </div>
        </div>

        <!-- 绑定钱包 -->
        <div class="wallet" v-if="loginDisplayModule === 'wallet'">
            <div class="row-center-between Heading-H4-Bold log-in-title">
                <p>Select Wallet</p>
                <svg-icon class="cur active" name="register-Group2" style="width:.48rem;height:.48rem;"
                    @svgChong="$router.back()" />
            </div>
            <div class="row-center-between wallet-selection">
                <div class="Heading-H4-Medium nabox column-center-center cur active" @click="bindWallet('NaboxWallet')">
                    <svg-icon name="register-nabox" />
                    <span>Nabox</span>
                </div>
                <div class="Heading-H4-Medium metamask column-center-center cur active" @click="bindWallet('ethereum')">
                    <svg-icon name="register-metamask" />
                    <span>MetaMask</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let hasPath;
import { mailRegister, mailSendCaptcha, mailLogin, sendChangePwdMailCaptcha, mailChangePwd, buildLoginNonce, mailCaptchaValid } from '@/api/register'
import { metamaskWallet } from '@/assets/js/metamask'
import Button from '@/components/Button/index.vue'
export default {
    components: {
        Button
    },
    data() {
        return {
            loading: false,
            check: false,
            Okerror: false,
            eMailCode: '',
            nickname: '',
            eMail: '',
            password: '',
            provider: {},
            repeatPassword: '',
            loginDisplayModule: 'login', //register 注册   login 登录   forgetThePassword 忘记密码 newPassword 设置新密码  verify 验证  wallet 钱包选择
            sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
            CodeText: 'Get code',
            Send: 'Send',
            CodeFlag: true,
            verifyCode: false,
            probe_pointer: true
        }
    },
    beforeRouteEnter(to, from, next) {
        hasPath = from.path;
        next()
    },
    created() {
        this.loginDisplayModule = this.$route?.query?.loginDisplayModule || 'login'
    },
    methods: {
        onChongInput() {
            this.Okerror = false
        },
        async buildLoginNonce(address) {
            const res = await buildLoginNonce(address)
            if (res.code == 0) {
                return res.data
            }
        },
        goBack() {
            if (hasPath === '/userInfo') {
                this.$router.push('/')
            } else {
                this.$router.go(-1)
            }
        },
        ForgotPassword() {
            this.verifyCode = false
            this.check = false
            this.eMail = ''
            this.eMailCode = ''
            this.loginDisplayModule = 'login'
        },
        async mailCaptchaValid() {
            const _this = this
            grecaptcha.ready(function () {
                grecaptcha.execute('6LdjsLQgAAAAAHIXq-jjf3GgDbZcpRkaKqXIpyvB', { action: 'submit' }).then(async function (token) {
                    const res = await mailCaptchaValid({
                        mail: _this.eMail,
                        captcha: token,
                        code: _this.eMailCode,
                    })
                    if (res.code === 0) {
                        _this.loginDisplayModule = 'newPassword'
                    }
                })
            })
        },
        async bindWallet(ethereum) {
            metamaskWallet(ethereum, this)
        },
        async UpdatePassword() {
            this.check = true
            const _this = this
            if (this.password && this.repeatPassword) {
                this.check = false
                if (this.repeatPassword === this.password) {
                    this.Okerror = false
                    const reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
                    if (reg.test(this.password)) {
                        grecaptcha.ready(function () {
                        grecaptcha.execute('6LdjsLQgAAAAAHIXq-jjf3GgDbZcpRkaKqXIpyvB', { action: 'submit' }).then(async function (token) {
                            const res = await mailChangePwd({
                                "captcha": token,
                                // "code": _this.eMailCode,
                                "mail": _this.eMail,
                                "pwd": _this.password,
                            })
                            if (res.code === 0) {
                                _this.$tool.message('The password has been reset successfully, and you will be redirected to the login page', 'success')
                                setTimeout(() => {
                                    _this.loginDisplayModule = 'login'
                                }, 800)
                                _this.eMailCode = ''
                                _this.password = ''
                                _this.repeatPassword = ''
                            } else {
                                _this.$tool.message(res.msg, 'error')
                            }
                        })
                    })
                    } else {
                        this.$tool.message('Password must contain numbers, special characters, uppercase and lowercase letters', 'error', 3000)
                    }
                } else {
                    this.Okerror = true
                }
            }
        },
        ResetPassword() {
            this.check = true
            this.verifyCode = true
            if (this.eMail && this.eMailCode) {
                this.check = false
                this.verifyCode = false
                this.mailCaptchaValid()
            }
        },
        async LogInConfing() {
            const _this = this
            this.check = true

            if (this.eMail && this.password) {
                if (this.loading) {
                    return false
                }
                _this.check = false
                this.loading = true
                grecaptcha.ready(() => {
                    grecaptcha.execute('6LdjsLQgAAAAAHIXq-jjf3GgDbZcpRkaKqXIpyvB', { action: 'submit' }).then(async (token) => {
                        const res = await mailLogin({
                            "captcha": token,
                            "mail": _this.eMail,
                            "pwd": _this.password
                        })
                        if (res.code === 0) {
                            _this.$tool.message('login successful', 'success')
                            localStorage.setItem('token', res.data.token)
                            _this.$store.commit({
                                type: 'setUserInfo',
                                val: res.data
                            })
                            if (!res.data.address) {
                                _this.loginDisplayModule = 'wallet'
                            } else {
                                if (hasPath == '/') {
                                    _this.$router.push('/');
                                    return;
                                }
                                _this.$router.go(-1);
                            }
                        } else {
                            _this.$tool.message(res.msg, 'error')
                        }
                        _this.loading = false
                    })
                })
            }

        },
        setModule(path) {
            //初始化
            this.verifyCode = false
            this.eMail = ''
            this.password = ''
            this.repeatPassword = ''
            this.eMailCode = ''
            this.nickname = ''
            this.check = false
            this.loginDisplayModule = path
        },
        passwordFocus(val) {
            if (val == '1') {
                // 登录
                if (this.password.length >= 6 && this.password.length <= 20) {
                    const reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
                    if (reg.test(this.password)) {
                    } else {
                        this.$tool.message('Password must contain numbers, special characters, uppercase and lowercase letters', 'error', 3000)
                    }
                } else {
                    this.$tool.message('Password length is 6 to 20 characters', 'error', 3000)
                }
            }
            if (val == '2') {
                if (this.password) {
                    if (this.password.length >= 6 && this.password.length <= 20) {
                        const reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
                        if (reg.test(this.password)) {
                        } else {
                            this.$tool.message('Password must contain numbers, special characters, uppercase and lowercase letters', 'error', 3000)
                        }
                    } else {
                        this.$tool.message('Password length is 6 to 20 characters', 'error', 3000)
                    }
                }
            }
            if (val == '3') {
                if (this.repeatPassword) {
                    if (this.repeatPassword.length >= 6 && this.repeatPassword.length <= 20) {
                        const reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
                        if (reg.test(this.repeatPassword)) {
                        } else {
                            this.$tool.message('Password must contain numbers, special characters, uppercase and lowercase letters', 'error', 3000)
                        }
                    } else {
                        this.$tool.message('Password length is 6 to 20 characters', 'error', 3000)
                    }
                }
            }
        },
        async RegisterPOST() {
            this.check = true
            this.verifyCode = true
            const _this = this
            if (this.eMailCode && this.eMail && this.password && this.repeatPassword && this.nickname) {
                _this.check = false
                _this.verifyCode = false
                if (this.password === this.repeatPassword && this.password && this.repeatPassword) {
                    const reg = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}');
                    if (reg.test(this.password)) {
                        grecaptcha.ready(function () {
                            grecaptcha.execute('6LdjsLQgAAAAAHIXq-jjf3GgDbZcpRkaKqXIpyvB', { action: 'submit' }).then(async function (token) {
                                // Add your logic to submit to your backend server here.
                                const res = await mailRegister({
                                    "captcha": token,
                                    "code": _this.eMailCode,
                                    "mail": _this.eMail,
                                    "pwd": _this.password,
                                    nikcName: _this.nickname
                                })
                                if (res.code === 0) {
                                    _this.$tool.message('The registration is successful, and you will be redirected to the login page', 'success')
                                    setTimeout(() => {
                                        _this.loginDisplayModule = 'login'
                                    }, 1000)
                                    _this.eMailCode = ''
                                    _this.password = ''
                                    _this.repeatPassword = ''
                                    _this.nickname = ''
                                } else {
                                    _this.$tool.message(res.msg, 'error')
                                }
                            });
                        });
                    } else {
                        this.$tool.message('Password must contain numbers, special characters, uppercase and lowercase letters', 'error', 3000)
                    }
                } else {
                    this.$tool.message('The two passwords entered are inconsistent')
                }
            }
        },
        async getEMailCode(bloer) {
            if (!this.CodeFlag) {
                return false
            }
            const regEmail = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
            if (regEmail.test(this.eMail)) {
                if (!this.probe_pointer) {
                    return false
                }
                this.probe_pointer = false
                this.verifyCode = false
                // 合法邮箱
                let res = null
                if (bloer) {
                    res = await mailSendCaptcha({
                        "mail": this.eMail
                    })
                } else {
                    res = await sendChangePwdMailCaptcha({
                        "mail": this.eMail
                    })
                }
                if (res.code === 0) {
                    if (this.CodeFlag) {
                        this.CodeFlag = false
                        let nubs = 60
                        this.setIN = setInterval(() => {
                            if (nubs === 0) {
                                this.CodeFlag = true
                                clearInterval(this.setIN)
                                if (bloer) {
                                    this.CodeText = 'Get code'
                                } else {
                                    this.Send = 'Send'
                                }
                                return false
                            }
                            nubs = (nubs - 1)
                            if (bloer) {
                                this.CodeText = nubs + ' s'
                            } else {
                                this.Send = nubs + ' s'
                            }
                        }, 1000);
                    }
                    this.$tool.message('The verification code has been sent to the mailbox', 'success')
                } else {
                    this.$tool.message(res.msg, 'error')
                }
                this.probe_pointer = true
            } else {
                this.verifyCode = true
                // this.$tool.message('Please enter a valid email address', 'error')
            }
        }
    }
}
</script>

<style scoped lang="less">
@import './index.less';
</style>